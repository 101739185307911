@import '../core/colors.sass'
	
#carbonads
	display: block
	overflow: hidden
	font-size: 12px
	border-radius: 5px
	font-family: Verdana, "Helvetica Neue", Helvetica, sans-serif
	line-height: 1.5
	a
		color: inherit
		&:hover
			color: inherit
	span
		display: block
		overflow: hidden
		font-size: 11px

#carbon
	position: relative
	.carbon-wrapper
		border: solid 1px #222222
		border-radius: 3px
		background-color: $secondary
		padding: 1em 0 calc(0.5em + 25px)
		margin-bottom: 1em
		min-height: 125px
		&.carbon-bottom
			margin-top: 1em
	.carbon-poweredby
		position: absolute
		bottom: 0
		display: block
		padding: .5em 0
		width: 100%
		color: #ddd !important
		text-align: center
		font-size: 10px
		border-top: solid 1px #222
	.carbon-wrap
		padding: 0 1em 0

.carbon-img
	display: block
	float: left
	margin-right: 1em
	img
		display: block

.carbon-text
	display: block
	float: left
	text-align: left
	max-width: calc(100% - 130px - 1em)

.hero, .carbon-container, .carbon-borderless
	.carbon-wrapper
		width: 95%
		max-width: 330px
		border: solid 1px #555
		border-radius: 5px
		padding: 0
		margin: 2em auto
	#carbonads
		position: relative
	.carbon-text
		max-width: calc(100% - 130px - 1.5em)
		margin: 0.5em 0.5em 0 0
	.carbon-poweredby
		position: absolute
		right: 0
		bottom: 0
		display: block
		font-size: 0.8em
		color: #e6f3fc
		margin: 0 0.5em 0.5em 0

.carbon-borderless
	.carbon-wrapper
		width: inherit
		margin: 0 auto 1em

.carbon-fixed
	position: fixed
	bottom: -0.5em
	left: 1em
	z-index: 999
	#carbonads
		background-color: $background



.bsa-cpc #_default_
	position: relative
	margin-bottom: 20px
	padding: .5em 1em
	border: solid 1px $hero-background
	border-radius: 5px
	background-color: #1e1e1e
	line-height: 1.5

.bsa-cpc .default-ad
	display: none

.bsa-cpc ._default_
	display: inline
	overflow: hidden
	line-height: 1.5

.bsa-cpc ._default_> *
	vertical-align: middle

.bsa-cpc a
	text-decoration: none

.bsa-cpc .default-image
	display: none

.bsa-cpc .default-title:after
	content: " — "

.bsa-cpc .default-title,
.bsa-cpc .default-description
	display: inline
	font-size: 13px

.bsa-cpc .default-title
	position: relative
	margin-left: 8px

.bsa-cpc .default-title:before
	position: relative
	top: -1px
	left: -8px
	padding: 2px 5px
	border: solid 1px #33c163
	border-radius: 3px
	color: #33c163
	content: "Sponsor"
	text-transform: uppercase
	font-size: 10px
	font-family: Verdana, sans-serif
	line-height: 1