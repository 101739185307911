@import "../core/colors.sass"

.footer
	background-color: $background
	margin-right: 15%


	.footer-logo
		padding-bottom: 80px
		width: 45px

	.social-text
		h3
			color: #F35353
			font-size: 12.5px
			font-weight: 300
			letter-spacing: 0.96px
			text-transform: uppercase
	
	.social-link
		margin-right: 35px
		margin-left: 5px

	.social-icon
		margin-top: 14px

	.copyright-notice 
		font-weight: 200
		font-size: 12px
		opacity: 0.3
		margin-top: 8px

	.footer-link-title
		display: block
		color: rgb(153, 156, 163)
		font-size: 14px
		font-weight: 600
		text-transform: uppercase
		margin-bottom: 20px

	.footer-link
		display: block
		color: #FFFFFF
		font-size: 16px
		font-weight: 500
		margin: 0.7em 0