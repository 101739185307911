@font-face
	font-family: 'Inter', Arial, Helvetica
	src: url('https://cdn.dyno.gg/fonts/Inter-Light.ttf') format("truetype")
	font-weight: 300
	font-style: italic

@font-face
	font-family: 'Inter', Arial, Helvetica
	src: url('https://cdn.dyno.gg/fonts/Inter-Light.ttf') format("truetype")
	font-weight: 300
	font-style: normal

@font-face
	font-family: 'Inter', Arial, Helvetica
	src: url('https://cdn.dyno.gg/fonts/Inter-Regular.ttf') format("truetype")
	font-weight: normal //400
	font-style: normal

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-Regular.ttf') format("truetype")
	font-weight: normal //400
	font-style: italic

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-Medium.ttf') format("truetype")
	font-weight: 600
	font-style: italic

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-Medium.ttf') format("truetype")
	font-weight: 600
	font-style: normal

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-SemiBold.ttf') format("truetype")
	font-weight: 600
	font-style: italic

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-SemiBold.ttf') format("truetype")
	font-weight: 600
	font-style: normal

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-Bold.ttf') format("truetype")
	font-weight: 700 //bold
	font-style: italic

@font-face
	font-family: 'Inter'
	src: url('https://cdn.dyno.gg/fonts/Inter-Bold.ttf') format("truetype")
	font-weight: 700 //bold
	font-style: normal

$notification-padding: 0
$notification-padding-ltr: 0
$notification-padding-rtl: 0

@import './colors.sass'

// import all
// @import 'bulma/bulma'

// utilities
@import 'bulma/sass/utilities/_all'
@import 'bulma/sass/base/generic'
@import 'bulma/sass/helpers/_all'
// Import minireset directly instead of bulma/sass/base/_all or bulma/sass/base/minireset for v0.0.5 which doesnt mess up ads
@import 'minireset.css/minireset.sass'

// layout
// @import 'bulma/sass/layout/hero'
@import 'bulma/sass/layout/section'
@import 'bulma/sass/layout/footer'

// elements
@import 'bulma/sass/elements/box'
@import 'bulma/sass/elements/button'
// @import 'bulma/sass/elements/content'
@import 'bulma/sass/form/_all'
@import 'bulma/sass/elements/image'
// @import 'bulma/sass/elements/notification'
@import 'bulma/sass/elements/other'
// @import 'bulma/sass/elements/progress'
@import 'bulma/sass/elements/table'
@import 'bulma/sass/elements/title'

// components
// @import 'bulma/sass/components/card'
@import 'bulma/sass/grid/_all'
// @import 'bulma/sass/components/highlight'
@import 'bulma/sass/components/level'
// @import 'bulma/sass/components/media'
@import 'bulma/sass/components/menu'
@import 'bulma/sass/components/message'
@import 'bulma/sass/components/modal'
@import 'bulma/sass/components/navbar'
// @import 'bulma/sass/components/navbar'
@import 'bulma/sass/components/pagination'
@import 'bulma/sass/components/dropdown'
// @import 'bulma/sass/components/panel'
// @import 'bulma/sass/components/tabs'

// .notification:not(:last-child) {
// 	margin-bottom: 0
// }

$break-tablet: 769px
$x-small: 576px
$break-mobile: 768px
$medium: $break-mobile
$large: 992px
$extra-large: 1200px
$extra-extra-large: 1400px
