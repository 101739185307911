@import "../core/base.sass"
@import "../partials/footer.sass"

.top-ad-container
  display: flex
  align-items: center
  justify-content: center
  margin-top: 20px
  overflow: hidden

.sticky-adhesion-container
  position: fixed
  bottom: 0
  z-index: 5000
  background-color: $secondary
  display: flex
  padding: 10px 0
  justify-content: center
  align-items: center
  height: fit-content
  width: 100%

.top-ad-container, .sticky-adhesion-container
  iframe
    &:first-of-type
      border-radius: 10px 0 10px 10px

.ad-container
  iframe
    &:first-of-type
      border-radius: 10px 0 10px 10px

.full-container
  width: 100%
  display: flex
  // margin: 0 3%
  justify-content: center

  .side-ad-rail
    margin-left: 3em
    margin-top: 0.5em
    padding: 0
    width: 350px

    .ads-column
      position: fixed
      display: flex
      flex-direction: column
      justify-content: flex-start
      justify-content: center
      top: 53%
      bottom: 50%
      gap: 20px

      .ad-container
        min-width: 300px
        min-height: 250px

nav.navbar
  height: fit-content
  background-color: transparent
  position: relative
  border-bottom: none
  padding-left: 0

  .navbar-container
    top: 0
    background-color: $topnav-background
    position: fixed
    width: 100%
    border: 1px solid rgb(24, 26, 32)
    height: fit-content
    display: flex
    flex-flow: column nowrap

  .navbar-brand
    padding-left: 1.5em
    padding-bottom: 0.4rem

  .touch-login-button
    margin: 0 0 3px

  .columns
    // max-width: 100vw
    .column
      padding: 0px

  .navbar-logo
    margin-top: 24px
    height: 41px
    margin-top: auto
    margin-bottom: auto

  .navbar-title h1
    font-size: 24px
    height: 41px
    line-height: 41px
    font-weight: 600
    text-transform: uppercase
    margin-left: 16px
    letter-spacing: 3px

  .branding
    padding-top: 1.8em

  .navbar-avatar
    border-radius: 900px
    border: 2px solid gray
    height: 42px
    width: 42px

  .title
    margin-top: auto
    margin-bottom: auto

  .navbar-burger-column, .navbar-auth-column
    display: none

  @include mobile
    .navbar-logo
      // width: 80px
      height: 28px
    .branding
      padding-top: 1em

  @include until($desktop)
    padding-left: 0px
    // padding-top: 0.60rem
    height: 60px

    .navbar-container
      padding-top: 0.60rem
      align-items: center
      padding-left: 0

      .navbar-brand
        width: 100%

    .divider
      display: none

    .navbar-brand
      height: 60px

    .navbar-menu
      background-color: $topnav-background
      position: absolute
      top: 0

    .columns
      margin-bottom: 0px

      .column
        padding: .75rem

    .navbar-burger-column
      align-content: center
      justify-content: flex-start
      display: flex

    .navbar-logo-column
      align-content: center
      justify-content: center
      display: flex

    .navbar-auth-column
      align-content: center
      justify-content: flex-end
      display: flex

  @include tablet
    .navbar-logo
      // width: 120px
      height: 41px

  @include desktop
    .navbar-menu
      display: flex !important

  .navbar-burger
    margin-left: 10px
    z-index: 5

    span
      background-color: #fff
      height: 2px
      width: 24px

      &:nth-child(1)
        top: calc(50% - 6px)

      &:nth-child(2)
        top: calc(50% + 1px)

      &:nth-child(3)
        top: calc(50% + 8px)

  a.navbar-item:hover
    background-color: unset

  .divider
    width: 1px
    height: 36px
    margin-top: 16px
    margin-bottom: 10px
    background-color: white
    opacity: 0.2
    margin-right: 20px
    margin-left: 7px

  .navbar-start
    position: relative
    background: $topnav-background
    margin-top: 0em
    margin-left: 0.5em

    a.navbar-item
      // opacity: 0.75
      &:hover
        color: $accent-red
    // opacity: 1'
    @media (max-width: 1024px)
      margin-top: 3.5em
    @media (max-width: 1020px)
  // display: none

  .navbar-item
    color: #ffffff
    font-family: Roboto, Inter, Arial, Helvetica
    font-size: 1rem
    font-weight: 600
    padding-left: 12px
    padding-right: 12px

    img
      max-height: none
      max-width: none

    &:focus, &:focus-within
      background-color: transparent

    .button
      font-size: 0.875rem

.has-text-primary
  color: #01E6CE !important

.has-text-grey
  color: #606a7b !important

.has-text-grey-1
  color: #707b8f !important

.has-text-grey-2
  color: #8692a5 !important

a.button
  border-radius: 4px
  font-family: 'Inter', Arial, Helvetica
  padding-top: 0.375em
  transition: background 0.3s, background-color 0.3s, color 0.3s

  &.is-info
    margin-right: 0.5em
    border-radius: 4px
    background-color: $info

    &:hover
      background: $info-hover
      // background: linear-gradient(0deg, rgb(255, 138, 138) 0%, rgb(236, 101, 95) 100%)
      // border: 2px solid rgba(255, 255, 255, 0.297979)
      // box-sizing: border-box
      text-decoration: none

  &.is-success
    background-color: $success

    &:hover
      background-color: lighten($success, 3%) !important

  .icon-text
    margin-left: 0.5em

  &.is-light
    background-color: $light-btn
    border-radius: 4px
    color: #FFFFFF

    &:hover
      background-color: darken($light-btn, 5%)
      color: #FFFFFF

  &.is-rounded
    border-radius: 3px

.is-flex-half
  width: 45%
  flex: 45%

.delete-btn-fade-text
  color: #da1039
  margin-left: 10px

  i
    margin-right: 4px

  .hidden-text
    opacity: 0
    transition: 300ms
    width: 100%

  &:hover
    .hidden-text
      opacity: 1
      transition: 300ms

.clone-btn-fade-text
  margin-right: 1em

.notification-container-bottom-right
  bottom: 80px !important

.notification__title
  font-weight: 700
  font-size: 14px
  margin-top: 5px
  margin-bottom: 5px

.notification__message
  max-width: calc(100% - 15px)
  font-size: 14px
  line-height: 150%
  word-wrap: break-word
  margin-bottom: 0
  margin-top: 0

.notification__custom-icon
  flex-basis: 20%
  position: relative
  padding: 8px 8px 8px 12px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 3px

  .fa
    color: #fff
    font-size: 28px

.notification__custom
  flex-basis: 80%
  padding: 12px 12px 12px 8px
  display: inline-block

.notification__custom--success
  width: 100%
  display: flex
  border-radius: 3px
  background-color: #28a745

  .notification__custom-icon
    border-left: 8px solid darken(#28a745, 15%)

.notification__custom--default
  width: 100%
  display: flex
  border-radius: 3px
  background-color: #007bff

  .notification__custom-icon
    border-left: 8px solid darken(#007bff, 15%)

.notification__custom--danger
  width: 100%
  display: flex
  border-radius: 3px
  background-color: #dc3545

  .notification__custom-icon
    border-left: 8px solid darken(#dc3545, 15%)

.notification__custom--info
  width: 100%
  display: flex
  border-radius: 3px
  background-color: #17a2b8

  .notification__custom-icon
    border-left: 8px solid darken(#17a2b8, 15%)

.notification__custom--warning
  width: 100%
  display: flex
  border-radius: 3px
  background-color: #eab000

  .notification__custom-icon
    border-left: 8px solid darken(#eab000, 15%)

.required
  color: #bb2e24

a, button, input, textarea, .Select-control, .Select-option, li, tr
  transition: color 0.3s, background 0.3s, background-color 0.3s

.dropdown
  .dropdown-menu
    .dropdown-content
      background-color: #181a20

      .dropdown-item
        &:hover
          background-color: #2A2B30
          color: #fff

div.wizard-modal
  width: 100%
  max-width: 1000px

  .setup-wizard
    height: 100%
    // overflow-y: scroll
    .title
      padding-bottom: 0
      border-bottom: unset
      margin-bottom: 0.7em

    .wizard-header
      line-height: 24px
      border-bottom: 1px solid #555
      padding-bottom: 15px

      .ant-steps-item-icon
        background: $info !important
        border-color: $info !important

    .wizard-content
      .setting
        margin-top: 1.5em

        &.full-page
          .label
            font-size: 24px

          .tip
            font-size: 14px

      .label
        font-weight: 500
        margin-bottom: 0

      .tip
        font-size: 12px
        color: #68727D

      .control, .has-addons
        margin-top: 0.5em

        input
          margin-left: 0

      .Select
        width: 75%

      .button
        margin-top: 1em

      p
        margin: 0

    .wizard-control
      margin-top: 1.5em

      .outlier
        float: right

  @media (max-width: 769px)
    min-width: 0

.file-dropzone
  margin: 0.5em 0
  border: dashed #555 3px
  height: 100px
  border-radius: 10px
  display: flex
  flex-direction: row
  justify-content: center
  padding-top: 35px
  color: #777
  text-align: center
  margin-bottom: 0.3em

  &.bg-image-container
    padding-top: 0

  &.banner
    padding-top: 0
    height: unset
    min-height: 100px
    max-width: 1000px
    max-height: 300px

    p
      margin: auto

    &.empty
      flex-direction: column

    img
      padding: 0.5em
      object-fit: cover

  &.avatar
    padding-top: 0
    min-height: 100px
    max-height: 200px
    height: 150px !important
    width: 150px

    p
      margin: auto

    &.empty
      flex-direction: column

    img
      padding: 0.5em
      object-fit: cover

code
  background-color: unset
  font-weight: bold

.MuiSlider-root
  width: 90%
  margin-left: 0.5em
  margin-right: 1em

  .MuiSlider-valueLabel
    background-color: $new-background

  .MuiSlider-rail
    background-color: lighten($new-background, 20%)

  .MuiSlider-track
    background-color: $info
    color: black

  .MuiSlider-mark
    background-color: black
    color: black

  .MuiSlider-thumb
    background-color: $info

    &:hover
      box-shadow: 0px 0px 0px 8px rgba(lighten($info, 10%), 0.3)

  .MuiSlider-markLabel
    color: white


hr.solid
  border-top: 0.01px solid #777d8f

.beta-toggle
  width: 100%
  display: flex
  justify-content: center
  flex-flow: row nowrap
  padding: 0.5rem 0
  border-bottom: 1px solid $footer-background
  margin: 0 auto 0.5rem
