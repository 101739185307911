// 202020
$body-background: rgb(14, 16, 21)
$body-background-color: rgb(14, 16, 21)
$background: rgb(14, 16, 21)
$topnav-background: rgb(12, 14, 19)
$nav-background: $topnav-background

$secondary: rgb(24, 26, 32)

$input-color: lighten($secondary, 7%)

$new-background: #2A2B30

$hero-background: $background
$footer-background: #1A2737

$accent-background: #111825

// $table-header-background: #336ed5
$table-header-background: $new-background

$info: #c1234f
$info-hover: darken($info, 13%)

$light-btn: lighten($secondary, 10%)
$light-btn-hover: darken($light-btn, 10%)

$text: #fff
$text-light: $text
$text-strong: $text

$title-color: $text
$label-color: $text

// $secondary: $accent-background

// 337fc1
$link: $info
$link-visited: $info
$link-hover: $info-hover

// $info: #337fc1
$success: #79a85d

$border: #222222

// 434857
$control-background: $accent-background
$control-border: #434857
$control-hover-border: #434857
$control-active-border: #434857

// $accent-green: #6dcc37
$accent-green: #009b54
// $accent-green: lighten($info, 2%)
$accent-pink: #ed6c63
$accent-red: #F35353

$topnav-color: #fff
$feature-text: #fff

$premium: #ff7f00
