@import '../core/base.sass'
	
@media screen and (max-width: $break-mobile)
	.page-home
		.hero .hero-content
			max-width: 100%
			margin: 0 10px
	.hero .hero-content
		max-width: inherit
		margin: 0 10px
	.nav-menu
		// background-color: #fff
		// box-shadow: 0 4px 7px rgba(17,17,17,0.1)
		box-shadow: none
		left: 0
		// display: none
		right: 0
		position: absolute
	.level-left + .level-right
		flex: 0
		margin-top: 0
		text-align: center
	nav.top-bar
		flex: 1 0 100%
		height: 165px
		.level-right
			display: none
	.top-bar .nav
		min-height: 0
		padding-top: 0
	.nav-left
		align-items: stretch
		display: flex
		flex: 1
		flex-wrap: wrap
		justify-content: center
		overflow: hidden
		overflow-x: auto
		white-space: nowrap
		width: 95%
	.nav-menu .nav-item
		border-top: none
		font-size: 12px
	.nav-discord
		display: none
	.hero
		.hero-content
			margin-left: 20px
		// .title, p
		// 	text-align: left
	.home .features
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: center
	.home .feature
		display: flex
		flex-direction: column
		justify-content: initial
		width: 200px
		height: 250px
		// background-color: #337fd5
		border-radius: 5px
		margin: 0 1em 25px /* increased margin for spacing */
		color: #fff
	.column
		aside.menu
			margin-bottom: 0
		h3.title.is-4
			margin-top: 0
