@import '../layouts/main.sass'
@import '../partials/carbon.sass'

.page-home
	.hero
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		min-height: 300px
		background: url('/images/v3/dyno-ghost.png') no-repeat center center
		background-color: $hero-background
		.title
			text-align: left
			font-size: 2em
			margin: 1em 0
			strong
				color: $info
				font-weight: 600
		h3
			text-align: left
			font-size: 1.8em
			margin: 0.6em 0
		p
			text-align: left
			margin: 1.5em 0
			font-size: 16px
		.button
			// margin: 0.5em 1em 0.5em 0
			width: 250px
			margin: 0.5em auto
		.hero-content
			max-width: 60%
		.column.is-6
			display: flex
			flex-direction: column
			justify-content: center
		.getting-started
			display: flex
			flex-direction: column
			justify-content: center
	.hero-small
		min-height: 50px
		background: $hero-background

.home
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	h3
		font-size: 30px
		margin: 1em 0
		text-align: center
	.features
		display: flex
		flex-direction: row
		flex-wrap: wrap
	.feature
		display: flex
		flex-direction: column
		justify-content: initial
		width: 200px
		height: 250px
		// background-color: $info
		border-radius: 5px
		border: solid $info 1px
		border-top: solid $info 0.5em
		background-color: $secondary
		margin: 0 1em
		color: $feature-text
		// &:first-child
		// 	margin-left: 0
		h4
			padding-top: 130px
			font-size: 18px
			font-weight: 600
			text-transform: uppercase
			text-align: center
			text-shadow: 1px 1px rgba(0,0,0,0.5)
		.feature-body
			display: flex
			flex-direction: column
			justify-content: flex-start
			height: 95px
			padding-top: 1em
			p
				display: block
				font-size: 12px
				line-height: 15px
				text-align: center
				padding: 0 1em 0
				text-shadow: 1px 1px rgba(0,0,0,0.5)
	.feature-moderation
		background: url('/images/v3/feature-moderation.png') no-repeat center 40%
	.feature-dashboard
		background: url('/images/v3/feature-dashboard.png') no-repeat center 40%
	.feature-music
		background: url('/images/v3/feature-music.png') no-repeat center 40%
	.feature-more
		background: url('/images/v3/feature-more.png') no-repeat center 40%

.upgrades
	display: flex
	flex-direction: row
	justify-content: wrap
.upgrade
	display: flex
	flex-direction: column
	justify-content: initial
	background-color: $info
	border-radius: 5px
	padding: 2.2em 1.5em 1.5em
	color: $feature-text
	text-shadow: 1px 1px rgba(0,0,0,0.5)
	max-width: 350px
	margin: 0 1em
	&:first-child
		margin-left: 0
	h3
		font-size: 24px
		margin: 0.6em 0
		text-align: center
	p, ul
		margin: auto
	p
		text-align: center
	p:not(:last-child)
		margin-bottom: 1em
	.icon.is-large
		font-size: 64px
		text-align: center
		margin: auto

@import '../partials/mobile.sass'